<template>
  <div>
    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col-4">
        <p class="text-subtitle1">Field details</p>

        <FieldLabel
          required
          autofocus
          :value="value.label"
          @input="onInput($event, 'label')"
          label="Select Options"
        />
        <FieldDescription
          :value="value.description"
          @input="onInput($event, 'description')"
          label="Description"
        />

        <FieldSecurityLevel
          :value="value.securityLevel"
          @input="onInput($event, 'securityLevel')"
          class="q-mb-md"
        />

        <p class="text-subtitle1">Field validation</p>

        <q-checkbox
          :value="value.required"
          @input="onInput($event, 'required')"
          label="Required"
        />
      </div>
      <div class="col-8">
        <p class="text-subtitle1">Field options</p>

        <Btn
          size="sm"
          class="q-mb-md"
          color="primary"
          label="Add option"
          @click="addOption"
        />

        <draggable
          v-if="selectOptions.length"
          v-model="selectOptions"
          group="templateFields"
          v-bind="dragOptions"
          @start="drag = true"
          @end="onDragEnd"
        >
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <FormInputSelectOption
              v-for="(option, index) in selectOptions"
              :key="`o-${index}`"
              :option="option"
              :autofocus="index === selectOptions.length - 1"
              @update="updateOptionValue($event, index)"
              @remove="removeOption(index)"
              @makeDefault="makeDefaultOption($event, index)"
            />
          </transition-group>
        </draggable>

        <p v-else>No options</p>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { getDraggableOptions } from '@/util/draggableOptions';
import FormInputSelectOption from '@/components/formInputConfig/FormInputSelectOption.vue';
import FieldSecurityLevel from '@/components/fields/FieldSecurityLevel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import Btn from '@/components/buttons/Btn.vue';

export default {
  name: 'FormInputConfigText',
  components: {
    FieldSecurityLevel,
    FormInputSelectOption,
    Btn,
    draggable,
    FieldDescription,
    FieldLabel,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drag: false,
      dragOptions: getDraggableOptions({ group: 'selectOptions' }),
      selectOptions: [...this.value.options],
    };
  },
  watch: {
    'value.options': function watchOptions(newVal) {
      this.selectOptions = newVal;
    },
  },
  methods: {
    onInput(value, field) {
      const data = { ...this.value };
      data[field] = value;
      this.$emit('input', data);
    },
    addOption() {
      this.selectOptions.push({
        label: '',
        value: '',
        sort: this.selectOptions.length,
        isDefault: false,
      });
      this.emitOptions();
    },
    removeOption(index) {
      this.selectOptions.splice(index, 1);
      this.emitOptions();
    },
    updateOptionValue(e, index) {
      const { value } = e.target;

      this.selectOptions[index].value = value;
      this.selectOptions[index].label = value;

      if (!value) {
        this.selectOptions[index].isDefault = false;
        this.emitOptions('', 'defaultValue');
      } else {
        this.emitOptions();
      }
    },
    makeDefaultOption(value, indexToUpdate) {
      let defaultValue = '';
      this.selectOptions.forEach((o, index) => {
        if (indexToUpdate === index) {
          o.isDefault = true;
          defaultValue = o.value;
        } else {
          o.isDefault = false;
        }
      });

      const data = { ...this.value };
      data.options = this.selectOptions;
      this.$emit('input', data);
      this.emitOptions(defaultValue, 'defaultValue');
    },
    onDragEnd() {
      this.drag = false;
      this.emitOptions();
    },
    emitOptions(value = '', field = '') {
      const data = { ...this.value };
      data.options = this.selectOptions.map((o, index) => {
        o.sort = index;
        return o;
      });

      if (field) {
        data[field] = value;
      }
      this.$emit('input', data);
    },
  },
};
</script>
