<template>
  <app-modal ref="modal" :title="title" @close="onClose">
    <q-form
      ref="form"
      style="width: 1000px;"
      @submit.prevent="submit"
      @keyup.enter="submit"
    >
      <ApiError :error="error"/>

      <div class="row q-col-gutter-xl">
        <div class="col-3">
          <p class="text-subtitle1 text-center full-width">Select a field type</p>
          <Btn
            v-for="option in fieldOptions"
            size="sm"
            :key="option.value"
            :label="option.label"
            :outline="option.value !== field.type"
            color="blue-grey-5"
            class="full-width q-mb-sm"
            @click="selectInputType(option.value)"
          />
        </div>

        <div class="col">
          <component
            v-if="configComponent"
            :is="configComponent"
            v-model="field"
          />
        </div>
      </div>

      <template>
        <q-separator class="q-my-md"/>
        <div class="flex justify-between">
          <q-btn label="Cancel" flat v-close-popup/>
          <q-btn :label="submitLabel" color="primary" @click="submit"/>
        </div>
      </template>
    </q-form>
  </app-modal>
</template>

<script>
import { fieldTypes, getFieldTypeOptions } from '@/components/formInputConfig/fields';
import { getDefaultInputConfig } from '@/components/formInputConfig/defaultInputConfig';
import AppModal from '@/components/AppModal.vue';
import FormInputConfigText from '@/components/formInputConfig/FormInputConfigText.vue';
import FormInputConfigNumber from '@/components/formInputConfig/FormInputConfigNumber.vue';
import FormInputConfigDate from '@/components/formInputConfig/FormInputConfigDate.vue';
import FormInputConfigCheckbox from '@/components/formInputConfig/FormInputConfigCheckbox.vue';
import FormInputConfigSelect from '@/components/formInputConfig/FormInputConfigSelect.vue';
import ApiError from '@/components/ApiError.vue';
import Btn from '@/components/buttons/Btn.vue';

export default {
  name: 'FormInputConfig',
  components: {
    Btn,
    ApiError,
    FormInputConfigDate,
    FormInputConfigNumber,
    FormInputConfigText,
    FormInputConfigCheckbox,
    FormInputConfigSelect,
    AppModal,
  },
  computed: {
    editActive() {
      return this.fieldIndex !== null;
    },
    title() {
      if (this.field) {
        return `Add field - ${this.field.type}`;
      }
      return 'Add field';
    },
    submitLabel() {
      if (this.editActive) {
        return 'Save changes';
      }
      return 'Add field';
    },
    configComponent() {
      switch (this.field.type) {
        case fieldTypes.NUMBER:
          return 'FormInputConfigNumber';
        case fieldTypes.DATE:
          return 'FormInputConfigDate';
        case fieldTypes.CHECKBOX:
          return 'FormInputConfigCheckbox';
        case fieldTypes.SELECT:
          return 'FormInputConfigSelect';
        default:
          return 'FormInputConfigText';
      }
    },
  },
  data() {
    return {
      error: '',
      field: {},
      fieldOptions: getFieldTypeOptions(),
      fieldIndex: null,
      groupIndex: null,
    };
  },
  methods: {
    selectInputType(value) {
      this.field = getDefaultInputConfig()[value];
    },
    async edit(e) {
      this.field = e.data;
      this.fieldIndex = e.fieldIndex;
      this.groupIndex = e.groupIndex;
      this.$refs.modal.open();
    },
    onClose() {
      this.fieldIndex = null;
      this.field = {};
    },
    async submit() {
      this.error = '';
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      if (this.field.type === fieldTypes.SELECT && !this.field.options.length) {
        this.error = 'Select fields must have at least one option';
        return;
      }

      if (this.editActive) {
        this.$emit('edit', {
          field: this.field,
          fieldIndex: this.fieldIndex,
          groupIndex: this.groupIndex,
        });
      } else {
        this.$emit('add', {
          field: this.field,
          groupIndex: this.groupIndex,
        });
      }
      this.close();
    },
    open(e) {
      this.groupIndex = e.groupIndex;

      if (e.fieldIndex) {
        this.fieldIndex = e.fieldIndex;
      }

      this.selectInputType(fieldTypes.TEXT);
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
