<template>
  <q-item class="bordered">
    <q-item-section side>
      <Btn flat icon="menu"/>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>Field Name</q-item-label>
      <q-item-label>{{ field.label }}</q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>Type</q-item-label>
      <q-item-label>{{ field.type }}</q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>Data security level</q-item-label>
      <q-item-label class="text-bold">
        <SecurityLevelCol tag="span" :row="{ securityLevel: field.securityLevel }" />
      </q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>Required</q-item-label>
      <q-item-label>{{ field.required }}</q-item-label>
    </q-item-section>
    <q-item-section side>
      <EditBtn @click="$emit('edit', field)"/>
    </q-item-section>
    <q-item-section side>
      <DeleteFormElement is-field @delete="$emit('remove')"/>
    </q-item-section>
  </q-item>
</template>

<script>
import Btn from '@/components/buttons/Btn.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';
import DeleteFormElement from '@/views/forms/DeleteFormElement.vue';

export default {
  name: 'FormInputListItem',
  components: {
    SecurityLevelCol,
    DeleteFormElement,
    EditBtn,
    Btn,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
};
</script>
