


























































import Vue, { VueConstructor, PropType } from 'vue';
import { FormWrapperRequest } from '@/types/form';
import formSubmit from '@/mixins/formSubmit';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import FormInputConfig from '@/components/formInputConfig/FormInputConfig.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import FormNestedDraggable from '@/views/forms/FormNestedDraggable.vue';
import {
  onDragend,
  offDragend,
  onAddField,
  offAddField,
  onEditContent,
  offEditContent,
  onRemoveContent,
  offRemoveContent,
  FormContentEditEvent,
  FormContentRemoveEvent,
} from '@/views/forms/formContentEvents';
import { DataFormGroup, DataFormField, DataForm } from '@/types/dataForm';
import notify from '@/util/notify';
import EditBtn from '@/components/buttons/EditBtn.vue';

function getDefaultModel() {
  return {
    label: '',
    description: '',
    groups: [
      {
        label: 'Default group',
        fields: [] as DataFormField[],
        sort: 0,
      },
    ] as DataFormGroup[],
    remove: [] as string[],
  };
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'FormModal',
  components: {
    EditBtn,
    FormNestedDraggable,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FormInputConfig,
    FieldDescription,
  },
  mixins: [formSubmit],
  props: {
    form: Object as PropType<DataForm>,
  },
  computed: {
    isEdit(): boolean {
      return !!this.form && !!this.form.id;
    },
    title(): string {
      return `${this.isEdit ? 'Edit' : 'New'} form`;
    },
    request(): FormWrapperRequest {
      if (this.isEdit) {
        return {
          config: {
            data: this.model,
            method: 'put',
            url: `project/${this.$route.params.projectId}/form/${this.form.id}`,
          },
        };
      }
      return {
        config: {
          data: this.model,
          method: 'post',
          url: `project/${this.$route.params.projectId}/form`,
        },
      };
    },
  },
  data() {
    return {
      model: getDefaultModel(),
    };
  },
  methods: {
    async open() {
      if (this.isEdit) {
        this.model.label = this.form.label;
        this.model.description = this.form.description;
        this.model.groups = [...this.form.groups];
      }

      (this.$refs.modal as any).open();
    },
    showInputConfig(groupIndex: number) {
      (this.$refs.inputConfig as any).open({ groupIndex });
    },
    onEdit(e: FormContentEditEvent) {
      if (e.type === 'group') {
        let hasDuplicate = false;
        this.model.groups.forEach((g) => {
          if (g.label === e.data.label && !hasDuplicate) {
            hasDuplicate = true;
          }
        });

        if (hasDuplicate) {
          notify.info(`Duplicate group label "${e.data.label}"`);
        } else {
          this.model.groups[e.groupIndex].label = e.data.label;
        }
      } else {
        (this.$refs.inputConfig as any).edit(e);
      }
    },
    onRemove(e: FormContentRemoveEvent) {
      if (e.type === 'group') {
        // push the fields to the removedFields array, then remove the group
        this.model.groups[e.groupIndex].fields.forEach((f) => {
          if (f.id) {
            this.model.remove.push(f.id);
          }
        });
        this.model.groups.splice(e.groupIndex, 1);
      } else {
        // push the field to the removedFields array, then remove it
        const fid = this.model.groups[e.groupIndex].fields[e.fieldIndex as number].id;
        if (fid) {
          this.model.remove.push(fid);
        }
        this.model.groups[e.groupIndex].fields.splice(e.fieldIndex as number, 1);
      }
    },
    addGroup() {
      this.error = '';

      const len = this.model.groups.length;
      let label = `Group ${len}`;
      const labelTaken = this.model.groups.some((g) => g.label === label);
      if (labelTaken) {
        label = `Group ${Date.now()}`;
      }

      this.model.groups.push({
        partial: false,
        label,
        fields: [] as DataFormField[],
        sort: this.model.groups.length,
      });
    },
    addInput(data: { fieldIndex: number, groupIndex: number, field: DataFormField }) {
      this.error = '';

      const group = this.model.groups[data.groupIndex];
      group.fields.push({
        ...data.field,
        sort: group.fields.length,
      });
      (this.$refs.inputConfig as any).close();
    },
    updateInput(data: { fieldIndex: number, groupIndex: number, field: DataFormField }) {
      // vue-draggable doesn't work really well with loops
      const group = this.model.groups[data.groupIndex];

      group.fields = group.fields.map((f, fIndex) => {
        if (fIndex === data.fieldIndex) {
          const updatedField = data.field;
          updatedField.sort = fIndex;
          return updatedField;
        }

        f.sort = fIndex;
        return f;
      });
    },
    onDragEnd(groupIndex?: number) {
      function mapAndSort<T extends { sort: number }>(arr: T[]) {
        return arr
          .map((el, index) => {
            el.sort = index;
            return el;
          })
          .sort((a, b) => a.sort - b.sort);
      }

      if (groupIndex !== undefined) {
        // sort fields
        this.model.groups[groupIndex].fields = mapAndSort(this.model.groups[groupIndex].fields);
      } else {
        this.model.groups = mapAndSort(this.model.groups);
      }
    },
    async onSubmit() {
      this.error = '';

      // keep only groups that have fields and groups
      //  that have total properties more than 0
      this.model.groups = this.model.groups.filter(
        (g) => (g.fields && g.fields.length > 0)
        || (g.totalProperties && g.totalProperties > 0),
      );

      if (!this.model.groups.length) {
        this.error = 'Must have at least one group that contains at least one field';

        if (this.isEdit) {
          this.model.groups = [...this.form.groups];
        } else {
          this.model.groups = getDefaultModel().groups;
        }
        return;
      }

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }

      if (this.isEdit) {
        this.notify('Form updated');
      } else {
        this.notify('Form created');
      }
      (this.$refs.modal as any).close();
      this.model = getDefaultModel();
      this.$emit('submit');
    },
  },
  mounted() {
    onDragend(this.onDragEnd);
    onAddField(this.showInputConfig);
    onEditContent(this.onEdit);
    onRemoveContent(this.onRemove);
  },
  beforeDestroy() {
    offDragend(this.onDragEnd);
    offAddField(this.showInputConfig);
    offEditContent(this.onEdit);
    offRemoveContent(this.onRemove);
  },
});
