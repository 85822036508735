import { SecurityLevel } from '@/types';

export const getDefaultInputConfig = () => ({
  text: {
    label: '',
    description: '',
    // data
    type: 'text',
    dataType: 'string',
    // we might add it later
    // textTransform: 'none',
    // validation
    required: false,
    // we might add them later
    // minLen: 0,
    // maxLen: 250,
    securityLevel: SecurityLevel.None,
  },
  textarea: {
    label: '',
    description: '',
    // data
    type: 'textarea',
    dataType: 'string',
    // we might add it later
    // textTransform: 'none',
    securityLevel: SecurityLevel.None,
    // validation
    required: false,
    // we might add them later
    // minLen: 0,
    // maxLen: 500,
  },
  email: {
    label: '',
    description: '',
    // data
    type: 'email',
    dataType: 'string',
    securityLevel: SecurityLevel.None,
    // validation
    required: false,
  },
  link: {
    label: '',
    description: '',
    // data
    type: 'link',
    dataType: 'string',
    securityLevel: SecurityLevel.None,
    // validation
    required: false,
  },
  number: {
    label: '',
    description: '',
    // data
    type: 'number',
    dataType: 'number',
    securityLevel: SecurityLevel.None,
    // validation
    required: false,
    // we might add them later
    // minValue: null,
    // maxValue: null,
  },
  date: {
    label: '',
    description: '',
    // data
    type: 'date',
    dataType: 'number',
    securityLevel: SecurityLevel.None,
    // validation
    required: false,
    // we might add them later
    // minValue: null,
    // maxValue: null,
  },
  // time: {
  //   label: '',
  //   description: '',
  //   // data
  //   type: 'time',
  //   dataType: 'number',
  //   securityLevel: SecurityLevel.None,
  //   // validation
  //   required: false,
  //   // we might add them later
  //   // minValue: null,
  //   // maxValue: null,
  // },
  checkbox: {
    label: '',
    description: '',
    // data
    type: 'checkbox',
    dataType: 'boolean',
    securityLevel: SecurityLevel.None,
  },
  select: {
    label: '',
    description: '',
    // data
    type: 'select',
    dataType: 'string',
    securityLevel: SecurityLevel.None,
    // validation
    required: false,
    options: [],
  },
});
