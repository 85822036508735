




















import Vue from 'vue';
import FormModal from '@/views/forms/FormModal.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';

export default Vue.extend({
  name: 'ListForms',
  components: {
    SecurityLevelCol,
    ListWithFilter,
    FormModal,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `project/${this.projectId}/forms/info`;
    },
    viewUrl(): string {
      return `/org/${this.orgId}/project/${this.projectId}/form`;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'maxSecurityLevel',
          label: 'Security Level',
          field: 'maxSecurityLevel',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
});
