<template>
  <div>
    <div class="row q-col-gutter-lg">
      <div class="col-4">
        <p class="text-subtitle1">Field details</p>

        <FieldLabel
          required
          autofocus
          :value="value.label"
          @input="onInput($event, 'label')"
          label="Number"
        />
        <FieldDescription
          :value="value.description"
          @input="onInput($event, 'description')"
          label="Description"
        />
      </div>
      <div class="col-4">
        <p class="text-subtitle1">Field data</p>

        <FieldSecurityLevel
          :value="value.securityLevel"
          @input="onInput($event, 'securityLevel')"
          class="q-mb-md"
        />
      </div>
      <div class="col-4">
        <p class="text-subtitle1">Field validation</p>

        <q-checkbox
          :value="value.required"
          @input="onInput($event, 'required')"
          label="Required"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import FieldSecurityLevel from '@/components/fields/FieldSecurityLevel.vue';

export default {
  name: 'FormInputConfigNumber',
  components: {
    FieldSecurityLevel,
    FieldDescription,
    FieldLabel,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxNumberValue: Number.MAX_SAFE_INTEGER,
    };
  },
  methods: {
    onInput(value, field) {
      const data = { ...this.value };
      data[field] = value;
      this.$emit('input', data);
    },
  },
};
</script>
