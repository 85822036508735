import EventBus from '@/util/EventBus';

export type FormContentEditEvent = {
  type: 'group' | 'field',
  data: any,
  fieldIndex?: number,
  groupIndex: number,
}

export type FormContentEditValueEvent = {
  value: any,
  formId: string;
  groupIndex: number;
  fieldIndex: number;
}

export type FormContentRemoveEvent = {
  type: 'group' | 'field',
  fieldIndex?: number,
  groupIndex: number,
}

export const formContentEventBus = new EventBus();

export const formContentEvents = Object.freeze({
  EDIT: 'edit',
  INPUT: 'input',
  ADD_FIELD: 'addField',
  REMOVE: 'remove',
  DRAGEND: 'dragend',
});

// EDIT
export function emitEditContent(e: FormContentEditEvent) {
  formContentEventBus.$emit(formContentEvents.EDIT, e);
}

export function onEditContent(listener: (e: FormContentEditEvent) => void) {
  formContentEventBus.$on(formContentEvents.EDIT, listener);
}

export function offEditContent(listener: (e: FormContentEditEvent) => void) {
  formContentEventBus.$off(formContentEvents.EDIT, listener);
}

export function emitInput(e: FormContentEditValueEvent) {
  formContentEventBus.$emit(formContentEvents.INPUT, e);
}

export function onInput(listener: (e: FormContentEditValueEvent) => void) {
  formContentEventBus.$on(formContentEvents.INPUT, listener);
}

export function offInput(listener: (e: FormContentEditValueEvent) => void) {
  formContentEventBus.$off(formContentEvents.INPUT, listener);
}

// REMOVE
export function emitRemoveContent(e: FormContentRemoveEvent) {
  formContentEventBus.$emit(formContentEvents.REMOVE, e);
}

export function onRemoveContent(listener: (e: FormContentRemoveEvent) => void) {
  formContentEventBus.$on(formContentEvents.REMOVE, listener);
}

export function offRemoveContent(listener: (e: FormContentRemoveEvent) => void) {
  formContentEventBus.$off(formContentEvents.REMOVE, listener);
}

// DRAGEND
export function emitDragend(groupIndex?: number) {
  formContentEventBus.$emit(formContentEvents.DRAGEND, groupIndex);
}

export function onDragend(listener: (groupIndex?: number) => void) {
  formContentEventBus.$on(formContentEvents.DRAGEND, listener);
}

export function offDragend(listener: (groupIndex?: number) => void) {
  formContentEventBus.$off(formContentEvents.DRAGEND, listener);
}

// Add field
export function emitAddField(groupIndex?: number) {
  formContentEventBus.$emit(formContentEvents.ADD_FIELD, groupIndex);
}

export function onAddField(listener: (groupIndex: number) => void) {
  formContentEventBus.$on(formContentEvents.ADD_FIELD, listener);
}

export function offAddField(listener: (groupIndex: number) => void) {
  formContentEventBus.$off(formContentEvents.ADD_FIELD, listener);
}
