<template>
  <draggable
    :list="elements"
    :group="{
      name: isField ? 'group' : 'field-group',
    }"
    @start="drag = true"
    @end="onDragEnd"
    @add="onDragEnd"
    class="relative-position"
    :options="{
      dragoverBubble: !isField,
      ghostClass: 'ghost',
      animation: 250,
    }"
  >
    <div v-for="(el, index) in elements" :key="`group-${index}`">
      <template v-if="el && el.fields">
        <form-group-list-item
          :group="el"
          can-drag
          can-edit
          :can-delete="elements.length > 1 && !el.partial"
          @edit="onEdit($event, index, 'group')"
          @remove="onRemove($event, index, 'group')"
        >
          <FormNestedDraggable
            is-field
            :elements="el.fields"
            :group-index="index"
            :partial="el.partial"
          />

          <div class="text-right">
            <Btn
              outline
              size="sm"
              label="Add field"
              icon="add"
              color="primary"
              class="q-my-md q-mr-md"
              @click="onAddField(index)"
            />
          </div>
        </form-group-list-item>
      </template>

      <template v-else>
        <FormInputListItem
          v-if="el"
          class="q-mx-lg q-mb-sm"
          :field="el"
          @edit="onEdit($event, index, 'field')"
          @remove="onRemove($event, index, 'field')"
        />
      </template>
    </div>

    <!-- Show drop area if the group is empty  -->
    <div v-if="isField && !elements.length && !partial" class="drop-area">
      <em class="block q-mt-md">
        This group is empty. Add a new field or drop fields from other groups here.
      </em>
    </div>
    <!-- Alternative message when the group has all this field held back -->
    <div v-else-if="isField && !elements.length && partial" class="drop-area">
      <em class="block q-mt-md">
        This group has fields with higher Security Level.
      </em>
      <em class="block q-mt-md">
        You can add a new field or drop fields from other groups here.
      </em>
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import FormInputListItem from '@/components/dataForm/FormInputListItem.vue';
import FormGroupListItem from '@/components/dataForm/FormGroupListIItem.vue';
import Btn from '@/components/buttons/Btn.vue';
// We use an event bus since we work with nested components
// and we want to avoid passing events up.
// Avoids this: nested-draggable -> draggable -> parent
import {
  emitDragend,
  emitAddField,
  emitEditContent,
  emitRemoveContent,
} from '@/views/forms/formContentEvents';

export default {
  name: 'FormNestedDraggable',
  components: {
    Btn,
    FormGroupListItem,
    FormInputListItem,
    draggable,
  },
  props: {
    elements: {
      required: true,
      type: Array,
    },
    isField: Boolean,
    groupIndex: Number,
    partial: Boolean,
  },
  data() {
    return {
      drag: false,
    };
  },
  methods: {
    onEdit(data, index, type) {
      if (type === 'group') {
        emitEditContent({
          type,
          data,
          groupIndex: index,
        });
      } else {
        emitEditContent({
          type,
          data,
          fieldIndex: index,
          groupIndex: this.groupIndex,
        });
      }
    },
    onRemove(data, index, type) {
      if (type === 'group') {
        emitRemoveContent({
          type,
          groupIndex: index,
        });
      } else {
        emitRemoveContent({
          type,
          fieldIndex: index,
          groupIndex: this.groupIndex,
        });
      }
    },
    onDragEnd() {
      this.drag = false;
      emitDragend(this.groupIndex);
    },
    onAddField(groupIndex) {
      emitAddField(groupIndex);
    },
  },
};
</script>
