<template>
  <q-item dense>
    <q-item-section side top class="q-pt-sm">
      <Btn flat size="sm" icon="menu"/>
    </q-item-section>
    <q-item-section>
      <FieldLabel
        required
        :autofocus="autofocus"
        :value="option.value"
        @change="$emit('update', $event)"
      />
    </q-item-section>
    <q-item-section side top class="q-pt-sm">
      <Btn flat size="sm" icon="clear" @click="$emit('remove')"/>
    </q-item-section>
  </q-item>
</template>

<script>
import Btn from '@/components/buttons/Btn.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';

export default {
  name: 'FormInputSelectOption',
  components: {
    FieldLabel,
    Btn,
  },
  props: {
    autofocus: Boolean,
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>
